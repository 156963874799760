import React from 'react'
import PropTypes from 'prop-types'

// import helmet for meta
import { Helmet } from "react-helmet"

// import router
import { Link, withRouter } from "react-router-dom"

// import i18n
import { withTranslation } from "react-i18next"

// import deep-equal
import equal from "deep-equal"

// import redux, actions
import { connect } from 'react-redux'
import {
    addOrder, evaluateOrder, listCustomers, payOrder, resetOrders, setOrders
} from 'store/actions'

// import bootstrap components
import {
    Container, Row, Col, Card, CardBody, NavItem, NavLink, TabContent, TabPane
} from "reactstrap"

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'
import Locator from 'components/common/Locator'

// import custom components
import ComposeTab from './ComposeTab'
import SummaryTab from './SummaryTab'
import SaveTab from './SaveTab'
import CustomerForm from './CustomerForm'

// import constants
import {
    APP_NAME, 
    CUSTOMER_CASUAL, 
    CUSTOMER_PARTNER, 
    DESCRIPTION_AUDIO,
    DESCRIPTION_TEXT,
    MAX_DESCRIPTION_INPUT,
    MIN_DESCRIPTION_INPUT,
    ORDERS_ADD, 
    ORDERS_EVALUATE, 
    PARTNERS_LIST, 
    USER_KEY
} from 'helpers/constants'

// import utilities
import { formatPhoneNumberForRequest, isNotEmptyOrBlank } from 'helpers/utilities'

// TABS
const FORM_TABS = [
    { key: "compose", label: "Composition" },
    { key: "summary", label: "Récapitulatif et Coût" },
    { key: "save", label: "Enregistrement" }
]


class CreateOrders extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            activeTab: FORM_TABS[0].key,
            locatorVisible: false,
            customerVisible: false,
            selectedPoint: {}
        }
    }

    componentDidMount() {
        try {// Check if agency user can access this page
            if (localStorage.getItem(USER_KEY)) {
                let { role, contact_point } = JSON.parse(localStorage.getItem(USER_KEY))
                //Redirect user, if it's not contact agency
                if (!contact_point) this.props.history.push("/agency-orders")
            }
        } catch (error) {
            console.error(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {//Check state updates
            if (!equal(prevProps, this.props)) {
                let { success, option } = this.props
                if ((!success) && (option === "")) {
                    if (prevProps.success && (prevProps.option === ORDERS_ADD)) {
                        this.setState({
                            activeTab: FORM_TABS[0].key,
                            locatorVisible: false,
                            customerVisible: false,
                            selectedPoint: {}
                        })
                    }
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetOrders(true)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Toggle form modal.
     * 
     * @param {*} option 
     */
    onToggled = (option = "locatorVisible", visible = false) => {
        this.setState({ [option]: visible })
    }

    /**
     * On Tab Toggled.
     * 
     * @param {*} tab 
     */
    onTabToggled = (tab) => {
        if (this.state.activeTab !== tab) this.setState({ activeTab: tab })
    }

    /**
     * onPrevious.
     * 
     * @param {*} currentTab 
     */
    onPrevious = (currentTab) => {
        try {
            if (currentTab !== FORM_TABS[0].key) {
                let currentIndex = FORM_TABS.findIndex((item) => (currentTab === item.key))
                this.onTabToggled(FORM_TABS[(currentIndex - 1)].key)
            }
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * onNext.
     * 
     * @param {*} currentTab 
     */
    onNext = (currentTab) => {
        try {
            let { customerVisible } = this.state
            if ((currentTab === "summary") && (!customerVisible)) {
                this.onCustomerToggled()
            } else if (currentTab !== FORM_TABS[(FORM_TABS.length - 1)].key) {
                if (customerVisible) this.onToggled("customerVisible", false)
                let currentIndex = FORM_TABS.findIndex((item) => (currentTab === item.key))
                let nextTab = FORM_TABS[(currentIndex + 1)].key
                if (this.onValidated(nextTab)) {
                    this.onTabToggled(nextTab)
                    if (nextTab === "summary") this.onEvaluated()
                    else if (nextTab === "save") this.onSaved()
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On Customer Toggled.
     * 
     */
    onCustomerToggled = () => {
        try {
            let { order, onSetOrders } = this.props
            let { customer_type } = order
            onSetOrders({
                order: {
                    ...order,
                    customer_type: (customer_type === undefined) ? CUSTOMER_CASUAL : customer_type
                }
            })
            this.onToggled("customerVisible", true)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * on validated.
     * Check if user inputs.
     * 
     * @param {*} nextTab 
     * @returns 
     */
    onValidated = (option = "save") => {
        try {
            let {
                pickup_point, pickup_contact, delivery_point, delivery_contact, nature,
                description_file, description_textual, description_type, cost,
                customer_name, customer_contact, selected_customer, customer_type
            } = this.props.order

            let validate_inputs = (
                (nature && nature !== "") &&
                (pickup_contact && pickup_contact !== "") &&
                (delivery_contact && delivery_contact !== "") &&
                (pickup_point && pickup_point.address && pickup_point.address !== "") &&
                (delivery_point && delivery_point.address && delivery_point.address !== "")
            )

            let validate_description = (
				((description_type === DESCRIPTION_AUDIO) && (description_file && description_file !== "")) ||
				(
					(description_type === DESCRIPTION_TEXT) && description_textual &&
					(
						(description_textual.length >= MIN_DESCRIPTION_INPUT) &&
						(description_textual.length <= MAX_DESCRIPTION_INPUT)
					)
				)
			)

            if (option === "summary") {
                return (validate_inputs && validate_description)
            } else if (option === "save") {
                let validate_partner = (
                    (customer_type === CUSTOMER_PARTNER) && selected_customer && selected_customer._id
                )

                let validate_casual = (
                    (customer_type === CUSTOMER_CASUAL) &&
                    (customer_name && customer_name !== "") &&
                    (customer_contact && customer_contact !== "") 
                )

                let validate_customer = (validate_partner || validate_casual)

                return (validate_inputs && validate_description && (cost && cost.total) && validate_customer)
            }
        } catch (error) {
            console.error(error)
            return false
        }
    }

    /**
     * on saved.
     * Submit form, Launch add or payment process.
     * 
     */
    onSaved = () => {
        try {
            let { order, onAddOrder } = this.props
            let { cost, pickup_point, delivery_point, selected_customer, customer_name, customer_contact } = order
            let is_audio = (order.description_type === DESCRIPTION_AUDIO)
            let is_partner = (order.customer_type === CUSTOMER_PARTNER)
            let customer_phone = (is_partner ? selected_customer.phone : customer_contact)
            onAddOrder({
                start_lat: pickup_point.lat,
                start_lng: pickup_point.lng,
                start_address: pickup_point.address,
                start_contact: formatPhoneNumberForRequest(order.pickup_contact),
                delivery_lat: delivery_point.lat,
                delivery_lng: delivery_point.lng,
                delivery_address: delivery_point.address,
                delivery_contact: formatPhoneNumberForRequest(order.delivery_contact),
                nature: order.nature,
                is_audio: is_audio,
                description_file: (is_audio && order.description_data) ? order.description_data : "",
                description_filename: (is_audio && order.description_data) ? "description.mp3" : "",
                description_textual: ((!is_audio) && order.description_textual) ? order.description_textual : "",
                customer_contact: formatPhoneNumberForRequest(customer_phone),
                customer_name: (is_partner ? selected_customer.name : customer_name),
                delivery_auth: order.delivery_auth ? order.delivery_auth : false,
                round_trip: order.round_trip ? order.round_trip : false,
                price: ((process.env.REACT_APP_ENV_PROD === "true") ? cost.total : 1),
            }, ORDERS_ADD)
        } catch (error) {
            console.error(error)
        }
    }

    /**
    * On changed.
    * 
    * @param {*} event 
    */
    onChanged = (event) => {
        try {
            //Get target, props values
            let { checked, value, name, type } = event.target
            let { order, onSetOrders } = this.props
            let { description_type } = order

            //Store values
            onSetOrders({
                order: {
                    ...order, [name]: ((type === "checkbox") ? checked : value)
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    /**
    * On selected.
    * 
    * @param {*} event 
    */
    onSelected = (selected, name) => {
        try {
            //Get props
            let { order, onSetOrders } = this.props

            //Store values
            onSetOrders({
                order: {
                    ...order, [name]: selected
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On switched.
     * 
     * @param {*} option 
     */
    onSwitched = (option, name) => {
        try {
            // Get props
            let { order, onSetOrders } = this.props

            // Store values
            onSetOrders({
                order: {
                    ...order, [name]: option
                }
            })

            // Load customers
            if (name === "customer_type") {
                let { partners, onListCustomers } = this.props
                if (partners.length === 0) onListCustomers(PARTNERS_LIST)
            }
        } catch (error) {
            console.error(error)
        }
    }

    /**
    * On Located.
    * 
    * @param {*} point 
    */
    onLocated = (point) => {
        try {
            this.setState({
                selectedPoint: point
            }, () => (
                this.onToggled("locatorVisible", true)
            ))
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On Picked.
     * 
     * @param {*} picked 
     */
    onPicked = (picked) => {
        try {
            let { order, onSetOrders } = this.props
            let { selectedPoint } = this.state
            this.setState({
                selectedPoint: { ...selectedPoint, ...picked }
            }, () => {
                onSetOrders({ order: { ...order, [selectedPoint.key]: { ...picked } } })
            })
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On Recorded.
     * 
     * @param {*} audio 
     */
    onRecorded = (audio) => {
        try {
            //Get props, audio values
            let { order, onSetOrders } = this.props
            let { link, data, initRecord } = audio
            let { description_file, description_data, description_type } = order

            //Set audio value
            onSetOrders({
                order: {
                    ...order,
                    description_file: (
                        initRecord ? null : (link !== null) ? link : description_file
                    ),
                    description_data: (
                        initRecord ? null : (data !== null) ? data : description_data
                    ),
                    description_type: ((description_type === undefined) ? DESCRIPTION_AUDIO : description_type)
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On order cost calculated.
     * 
     */
    onEvaluated = () => {
        try {
            const { onEvaluateOrder, order } = this.props
            if (order && order.pickup_point && order.delivery_point) {
                let { pickup_point, delivery_point, round_trip } = order
                onEvaluateOrder({
                    s_lat: pickup_point.lat, s_lng: pickup_point.lng,
                    d_lat: delivery_point.lat, d_lng: delivery_point.lng,
                    rt: (round_trip ? round_trip : false)
                }, ORDERS_EVALUATE)
            }
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * on paid.
     * Payment process callback, after order creation.
     * 
     * @param {*} response 
     */
    onPayed = (response) => {
        try {
            // Get props, response
            let { onSetOrders, order, onPayOrder, option } = this.props
            let { transactionId } = response

            // Get transaction
            let transaction = (isNotEmptyOrBlank(transactionId) ? transactionId : "")

            //Store values
            onSetOrders({
                order: {
                    ...order, 
                    cost: {
                        ...order.cost,
                        transaction_id: transaction
                    }
                }
            })

            // Launch request
            onPayOrder({
                id: order._id,
                code: order.code,
                data: {
                    transaction_id: transaction,
                    order_code: order.code
                }
            }, option)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * on continued.
     * Continue from customer form callback.
     * 
     */
    onContinued = () => {
        try {
            // Get state
            let { activeTab } = this.state

            // Go to next tab
            this.onNext(activeTab)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * on reseted.
     * Reset form after add process.
     * 
     */
    onRestarted = () => {
        try {//Reset state values
            this.props.onResetOrders(true)
        } catch (error) {
            console.error(error)
        }
    }


    render() {
        const { t, order, loading, success, error, option, partners, cust_loading, cust_option, cust_error, cust_success } = this.props
        const { activeTab, locatorVisible, selectedPoint, customerVisible } = this.state

        const TABS_SIZE = FORM_TABS.length

        return (
            <React.Fragment>
                <Helmet>
                    <title>{APP_NAME} | {t("Créer une course")}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={t("Créer une course")} />

                        <Row className="mb-2">
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <div className="wizard clearfix">
                                            <div className="steps clearfix">
                                                <ul>
                                                    {FORM_TABS.map((item, index) => (
                                                        <NavItem
                                                            key={`form_nav_item${index}`}
                                                            className={(activeTab === item.key) ? "current" : ""}
                                                        >
                                                            <NavLink className={`d-cursor${(activeTab === item.key) ? " active" : ""}`}>
                                                                <span className="number">
                                                                    {`${index + 1}`}.
                                                                </span>{" "}
                                                                {item.label}
                                                            </NavLink>
                                                        </NavItem>
                                                    ))}
                                                </ul>
                                            </div>

                                            <div className="content clearfix">
                                                <TabContent activeTab={activeTab} className="body">
                                                    {FORM_TABS.map((item, index) => (activeTab == item.key) && (
                                                        <TabPane key={`form_tab_item_${index}`} tabId={item.key}>
                                                            {(item.key === "compose") ? (
                                                                <ComposeTab
                                                                    order={order}
                                                                    onChanged={this.onChanged}
                                                                    onLocated={this.onLocated}
                                                                    onRecorded={this.onRecorded}
                                                                    onSwitched={this.onSwitched}
                                                                />
                                                            ) : (item.key === "summary") ? (
                                                                <SummaryTab
                                                                    option={option}
                                                                    loading={loading}
                                                                    error={error}
                                                                    order={order}
                                                                    onEvaluated={this.onEvaluated}
                                                                />
                                                            ) : (item.key === "save") ? (
                                                                <SaveTab
                                                                    order={order}
                                                                    loading={loading}
                                                                    option={option}
                                                                    error={error}
                                                                    success={success}
                                                                    onRestarted={this.onRestarted}
                                                                    onPayed={this.onPayed}
                                                                />
                                                            ) : (
                                                                <p className="m-0 p-2">
                                                                    Section inconnue
                                                                </p>
                                                            )}
                                                        </TabPane>
                                                    ))}
                                                </TabContent>
                                            </div>

                                            <div className="actions clearfix">
                                                {(success && (option === ORDERS_ADD)) ? null : (
                                                    <ul>
                                                        <li className={(activeTab === FORM_TABS[0].key) ? "previous disabled" : "previous"}>
                                                            <Link to="#" onClick={() => this.onPrevious(activeTab)}>
                                                                {t("Précédent")}
                                                            </Link>
                                                        </li>
                                                        <li className={(activeTab === FORM_TABS[(TABS_SIZE - 1)].key) ? "next disabled" : "next"}>
                                                            <Link to="#" onClick={() => this.onNext(activeTab)}>
                                                                {t("Suivant")}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                )}
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {(locatorVisible) ? (
                    <Locator
                        opened={locatorVisible}
                        onClosed={this.onToggled}
                        onPicked={this.onPicked}
                        point={selectedPoint}
                    />
                ) : null}
                {(customerVisible) ? (
                    <CustomerForm
                        order={order}
                        opened={customerVisible}
                        onClosed={this.onToggled}
                        onChanged={this.onChanged}
                        onSelected={this.onSelected}
                        onContinued={this.onContinued}
                        onSwitched={this.onSwitched}
                        loading={cust_loading}
                        option={cust_option}
                        error={cust_error}
                        success={cust_success}
                        partners={partners}
                    />
                ) : null}
            </React.Fragment>
        )
    }

}
CreateOrders.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any
}
const mapStateToProps = state => ({
    order: state.commandes.order,
    error: state.commandes.error,
    option: state.commandes.option,
    success: state.commandes.success,
    loading: state.commandes.loading,
    partners: state.clients.customers,
    cust_error: state.clients.error,
    cust_option: state.clients.option,
    cust_success: state.clients.success,
    cust_loading: state.clients.loading
})
const mapDispatchToProps = dispatch => ({
    onSetOrders: (data) => dispatch(setOrders(data)),
    onResetOrders: (all) => dispatch(resetOrders(all)),
    onEvaluateOrder: (data, option) => dispatch(evaluateOrder(data, option)),
    onAddOrder: (data, option) => dispatch(addOrder(data, option)),
    onListCustomers: (option) => dispatch(listCustomers(true, true, option)),
    onPayOrder: (data, option) => dispatch(payOrder(data, option))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(CreateOrders)))